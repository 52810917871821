<!-- Provides controls to modify global site settings. -->
<template>
  <div class="admin-container">
    <div class="claim-generation form-container">
      <h4>Claim Generation</h4>
      <TextInput
        v-model="newClaim.email"
        label="Recipient Email"
        small
        required
      />
      <v-select
        v-model="newClaim.collection"
        outlined
        hide-details
        placeholder="Choose collection"
        :items="tokenContracts || []"
        item-text="name"
        item-value="id"
        @change="changeCollection"
      ></v-select>

      <v-select
        v-model="newClaim.typePrimary"
        outlined
        hide-details
        placeholder="Choose Primary Type"
        :items="primaryTypes(newClaim.collection)"
        item-text="name"
        item-value="id"
        @change="changePrimaryType"
      ></v-select>

      <v-select
        v-model="newClaim.typeSecondary"
        outlined
        hide-details
        placeholder="Choose Secondary Type"
        :items="secondaryTypes(newClaim.collection, newClaim.typePrimary)"
        item-text="name"
        item-value="id"
        @change="updateIssuance"
      ></v-select>

      <TextInput
        v-model="newClaimIssuance"
        label="Issuance"
        small
        type="number"
        placeholder="0"
        disabled
      />
      <button
        class="standard"
        :disabled="generatingClaim || !readyToGenerate"
        @click="addNewClaim"
      >
        Create claim:
      </button>
      <p class="claim-id">{{ newClaimId }}</p>
    </div>
    <div class="form-container">
      <h4>Market Settings</h4>
      <v-checkbox v-model="settings.siteDisabled" label="Disable site?" small />
      <TextInput v-model="settings.marketName" label="Market Name" small />
      <TextArea v-model="settings.marketDescription" label="Market Description" />
      <v-checkbox v-model="settings.useLogo" label="Use custom logo?"></v-checkbox>
      <v-select v-model="settings.marketHome" :items="marketHomeItems" label="Market Home Page" />
      <TextInput v-model="settings.marketContract" label="Market Contract" small />
      <TextInput v-model="settings.marketWallet" label="Market Wallet" small />
      <v-checkbox v-model="settings.useCollectionGroups" label="Use collection groups?"></v-checkbox>

      <h4>Promotions</h4>
      <ActivePromotionsEditor
        title="Login Promotions"
        :activePromotionIds="settings.activePromotionIds"
        targetPromotionArray="activePromotionIds"
        @update="updateActivePromotions"
      />
      <ActivePromotionsEditor
        title="Special Promotions"
        :activePromotionIds="settings.activeSpecialPromotionIds"
        targetPromotionArray="activeSpecialPromotionIds"
        @update="updateActivePromotions"
      />
      <ActivePromotionsEditor
        title="Live Claiming Promotions"
        :activePromotionIds="settings.activeLiveClaimingIds"
        targetPromotionArray="activeLiveClaimingIds"
        @update="updateActivePromotions"
      />
      <button
        class="standard"
        :disabled="!changed"
        @click="saveSettings"
      >
        Save
      </button>
    </div>

    <div class="form-container">
      <TheFaqEditor />
    </div>

    <div class="form-container">
      <h4>Actions</h4>
      <TextInput v-model="newAdminUID" label="Create Admin User" placeholder="Firebase UID" />
      <button class="standard" @click="assignAdmin">Assign Admin Privileges</button>
      <p class="label">Add current Firebase users to SendGrid contacts</p>
      <button
        class="standard"
        @click="addAllUsersToSendgrid"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isEqual, cloneDeep } from 'lodash'
import TheFaqEditor from '@/components/admin/TheFaqEditor'
import ActivePromotionsEditor from '@/components/admin/ActivePromotionsEditor'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
export default {
  components: {
    TheFaqEditor,
    ActivePromotionsEditor
  },

  async created () {
    this.init()
    await this.tokenContractsListener()
    await this.issuanceListener()
    await this.loadPromotions()
  },

  beforeDestroy () {
    this.issuanceListenerUnsub()
  },

  data () {
    return {
      issuanceListenerUnsub: null,
      claimMetadata: {},
      typeCap: null,
      generatingClaim: false,
      newClaimIssuance: 0,
      newClaim: {
        claimed: false
      },
      newAdminUID: null,
      settings: {},
      initialSettings: {},
      changed: false,
      marketHomeItems: [
        '/market',
        '/artists',
        '/collection-groups',
        '/newsletter'
      ]
    }
  },

  computed: {
    ...mapGetters([
      'globalSettings',
      'tokenContracts',
      'primaryTypes',
      'secondaryTypes',
      'promotions'
    ]),

    readyToGenerate () {
      const { email, collection, typePrimary, typeSecondary } = this.newClaim
      return email && collection && typePrimary && typeSecondary
    },

    newClaimType () {
      return `${this.newClaim.typePrimary}.${this.newClaim.typeSecondary}`
    },

    newClaimId () {
      return `${this.newClaim.collection}.${this.newClaimType}.${this.newClaimIssuanceFormatted}`
    },

    newClaimIssuanceFormatted () {
      return String(this.newClaimIssuance).padStart(this.typeCap?.length, '0')
    }
  },

  methods: {
    ...mapActions([
      'getGlobalSettings',
      'updateGlobalSettings',
      'tokenContractsListener',
      'loadPromotions'
    ]),

    /**
     * Keep track of initial settings in order to know whether or not saving should be enabled.
     */
    init () {
      this.settings = { ...this.globalSettings }
      this.initialSettings = cloneDeep(this.settings)
    },

    /**
     * Manually generate a claim for a user.
     */
    async addNewClaim () {
      this.generatingClaim = true

      try {
        const { collection, typePrimary, typeSecondary, email } = this.newClaim
        const res = await this.fbCall('addNewClaim', {
          collection,
          typePrimary,
          typeSecondary,
          email
        })
        console.log(res)
        this.addNotification(`Claim ${res.data} added.`, 'success')
      } catch (err) {
        console.log(err)
        this.addNotification('Error adding claim.', 'error')
      }
      this.generatingClaim = false
    },

    updateActivePromotions (input) {
      const targetPromoType = input.target
      if (!this.settings[targetPromoType]) {
        const copy = JSON.parse(JSON.stringify(this.settings))
        copy[targetPromoType] = []
        this.settings = JSON.parse(JSON.stringify(copy))
      }
      if (!this.settings[targetPromoType].includes(input.promoId)) {
        this.settings[targetPromoType].push(input.promoId)
      } else {
        this.addNotification('That promotion is already active.', 'info')
      }
    },

    /**
     * Saves the updated settings to the Firestore database.
     */
    async saveSettings () {
      console.log('Updating settings:', this.settings)
      try {
        await this.updateGlobalSettings(this.settings)
        this.addNotification('Updated global settings.', 'success')
      } catch (err) {
        console.log(err)
        this.addNotification('Error updating global settings.', 'error')
      }
      try {
        await this.getGlobalSettings()
        this.init()
      } catch (err) {
        console.log(err)
        this.addNotification('Error refreshing global settings.', 'error')
      }
    },

    /**
     * Watches the claims metadata to determine the appropriate issuance ID for the next claim to be generated.
     */
    async issuanceListener () {
      this.issuanceListenerUnsub = await onSnapshot(doc(this.$fb.db, 'claims', 'metadata'), (metadata) => {
        this.claimMetadata = metadata.data()
        this.updateIssuance()
      })
    },

    async capQuery () {
      if (!this.newClaim.collection || !this.newClaim.typePrimary || !this.newClaim.typeSecondary) return
      const res = await getDoc(
        doc(this.$fb.db, 'token-contracts', this.newClaim.collection, 'types', this.newClaimType)
      )
      this.typeCap = res.data() ? res.data().cap : 0
    },

    changeCollection () {
      this.updateIssuance()
    },

    changePrimaryType () {
      this.updateIssuance()
    },

    async updateIssuance () {
      await this.capQuery()
      this.newClaimIssuance = this.claimMetadata[this.newClaimType] + 1 || 1
    },

    async assignAdmin () {
      try {
        await this.fbCall('assignAdmin', this.newAdminUID)
        this.addNotification(`Successfully granted ${this.newAdminUID} admin privileges.`)
        this.newAdminUID = null
      } catch (err) {
        this.addNotification('Error adding admin user.', 'error')
      }
    },

    addAllUsersToSendgrid () {
      try {
        this.fbCall('sendgrid-addAllUsersToSendgrid')
      } catch (err) {
        this.addNotification(err.message, 'error')
      }
    }
  },
  watch: {
    settings: {
      handler (val) {
        if (isEqual(val, this.initialSettings)) {
          this.changed = false
        } else {
          this.changed = true
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-container {
  .v-select {
    margin-bottom: $space-m;
  }
  h4 {
    margin-bottom: $space-m;
  }
  .admin-links {
    margin-bottom: $space-l;
    a {
      display: block;
    }
  }
  .claim-id {
    font-size: $font-size-xs;
    margin-top: $space-ms;
  }
  p.label {
    margin: $space-l 0 $space-m;
  }
}
</style>
