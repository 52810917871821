<template>
  <div class="promotions-editor">
    <h5>{{ title }}</h5>
    <v-select
      v-model="newActivePromo"
      :items="promotions"
      item-text="name"
      item-value="id"
      label="Add Promotion"
      hide-details
    />
    <button
      v-if="newActivePromo"
      class="standard"
      @click="addActivePromo"
    >
      Add to active promotions
    </button>
    <div class="active-promotions">
      <p
        v-for="(promo, index) in activePromotionIds"
        :key="promo.id"
        class="active-promo"
      >
        {{ promo }}
        <button @click="removePromo(index)">X</button>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: 'Promotions'
    },
    activePromotionIds: {
      type: Array,
      default: () => []
    },
    targetPromotionArray: {
      type: String,
      default: null,
      required: true
    }
  },
  data () {
    return {
      newActivePromo: null
    }
  },
  computed: {
    ...mapGetters(['promotions'])
  },
  methods: {
    /**
     * Add a promotion ID to the list of active promotions.
     */
    addActivePromo () {
      this.$emit('update', { target: this.targetPromotionArray, promoId: this.newActivePromo })
    },

    /**
     * Remove an active promo at the specified index.
     */
    removePromo (index) {
      this.activePromotionIds.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
h5 {
  font-size: $font-size-m;
  font-family: $font-title;
  text-transform: uppercase;
}
.v-select {
  margin-bottom: $space-m;
}
.active-promotions {
    margin-bottom: $space-m;
    .active-promo {
      padding: $space-s 0;
      border-bottom: solid 1px $blk-grey-5;
    }
  }
</style>
