<template>
  <div class="faq-editor">
    <h4 class="faq-editor__header">FAQ</h4>
    <Draggable v-model="faqItems" @end="orderEdited = true">
      <div
        v-for="(item, index) in faqItems"
        :key="`qa-${index}`"
        class="faq-editor__single"
      >
        <v-icon class="faq-editor__drag-icon">mdi-reorder-horizontal</v-icon>
        <TextInput
          v-model="item.q"
          small
          @input="edit(index)"
        />
        <v-icon
          class="faq-editor__single__expander"
          role="button"
          @click="expandFaq(index)"
        >
          <template v-if="faqEditorSettings[index].expanded">mdi-chevron-up</template>
          <template v-else>mdi-chevron-down</template>
        </v-icon>
        <v-expand-transition>
          <div v-if="faqEditorSettings[index].expanded" class="rte-container">
            <RichTextEditor
              @input="edit(index)"
              v-model="item.a"
              :value="item.a"
            />
            <div class="button-group">
              <button class="standard" @click="deleteItem(index)">
                Delete
              </button>
              <button
                v-if="faqEditorSettings[index].edited"
                class="standard save"
                @click="saveEdits(index)"
              >
                Save
              </button>
            </div>
          </div>
        </v-expand-transition>
      </div>
    </Draggable>

    <div class="button-group">
      <button class="standard new" @click="addFaq">
        New FAQ
      </button>
      <button
        v-if="orderEdited"
        class="standard save"
        @click="saveOrder"
      >
        Save Order
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import {
  doc,
  collection,
  query,
  setDoc,
  addDoc,
  deleteDoc,
  onSnapshot,
  orderBy,
  writeBatch
} from 'firebase/firestore'

export default {
  name: 'TheFaqEditor',
  components: {
    Draggable
  },
  data () {
    return {
      faqItems: [],
      faqEditorSettings: [],
      orderEdited: false
    }
  },
  computed: {
    ...mapGetters([
      'marketSettings'
    ])
  },
  mounted () {
    this.faqListener()
  },
  methods: {
    faqListener () {
      onSnapshot(query(collection(this.$fb.db, 'market-settings', 'global', 'faq'), orderBy('order')), (faqs) => {
        const faqItems = []
        faqs.forEach((faq) => {
          const copy = faq.data()
          copy.id = faq.id
          this.faqEditorSettings.push({
            expanded: false,
            edited: false
          })
          faqItems.push(copy)
        })
        this.faqItems = faqItems
      })
    },
    expandFaq (index) {
      this.faqEditorSettings[index].expanded = !this.faqEditorSettings[index].expanded
    },
    edit (index) {
      this.faqEditorSettings[index].edited = true
    },
    addFaq () {
      this.faqItems.push({
        q: '',
        a: ''
      })
      this.faqEditorSettings.push({
        expanded: true,
        edited: true
      })
    },
    async saveOrder () {
      const batch = writeBatch(this.$fb.db)
      for (let i = 0; i < this.faqItems.length; i++) {
        this.faqItems[i].order = i
        batch.update(
          doc(this.$fb.db, 'market-settings', 'global', 'faq', this.faqItems[i].id),
          { order: i }
        )
      }
      batch.commit().then(() => {
        this.addNotification('Order updated.', 'success')
        this.orderEdited = false
      })
    },
    async saveEdits (index) {
      try {
        const faqId = this.faqItems[index].id
        const faqValue = { q: this.faqItems[index].q, a: this.faqItems[index].a, order: index }
        if (faqId) {
          await setDoc(
            doc(this.$fb.db, 'market-settings', 'global', 'faq', faqId),
            faqValue,
            { merge: true }
          )
        } else {
          await addDoc(
            collection(this.$fb.db, 'market-settings', 'global', 'faq'),
            faqValue
          )
        }
        this.addNotification('FAQ Updated.', 'success')
        this.faqEditorSettings[index].edited = false
      } catch (err) {
        console.log(err)
        this.addNotification('Error updating FAQ.', 'error')
      }
    },

    async deleteItem (index) {
      if (!confirm('Are you sure you want to delete this FAQ item?')) return
      try {
        await deleteDoc(doc(this.$fb.db, 'market-settings', 'global', 'faq', this.faqItems[index].id))
        this.addNotification('Deleted FAQ item.', 'success')
      } catch (err) {
        this.addNotification('Error deleting FAQ item.', 'error')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-editor {
  &__drag-icon {
    cursor: grab;
  }
  &__header {
    margin-bottom: $space-m;
  }

  &__single {
    position: relative;
    border: solid 2px white;
    padding: $space-s;
    margin-bottom: $space-m;

    &__expander {
      position: absolute;
      top: $space-s;
      right: $space-s;
    }

    ::v-deep .field-container {
      margin-bottom: 0;
    }

    .button-group {
      margin-top: $space-m;
    }
  }
}
</style>
